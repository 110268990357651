import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLazyQuery } from '@apollo/client';
import { setSearchText } from '../app/universalSearchValueSlice';
import { addMaterialData } from '../app/materialSlice';
import { addProcessData } from '../app/processSlice';
import { debounce } from 'lodash';
import { LIST_MATERIALS } from '../Queries';
import { LIST_PROCESS } from '../Queries';
import SearchBar from '../components/SearchBar';
import TableWrapper from '../containers/TableWrapper';
import LazyButton from '../components/LazyButton';
import styled from 'styled-components';

const TableScrollerWrapper = styled.div`
  max-height: calc(100vh - 50px);
  overflow-y: scroll;
`;

const TableCountWrapper = styled.div`
  color: rgba(255,255,255,0.87);
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const TableCountVal = styled.div``;


function SearchResults() {
  const dispatch = useDispatch();
  const activeTab = useSelector((state) => state.tab.value);
  const [search, { loading, data }] = useLazyQuery(activeTab === 'material' ? LIST_MATERIALS : LIST_PROCESS);
  const debouncer = useCallback(debounce(search, 1000), []);

  useEffect(() => {
    data && activeTab === 'material' && dispatch(addMaterialData(data.listMaterialsWithCarbonEmissions));
    data && activeTab === 'process' && dispatch(addProcessData(data.listProcessWithCarbonEmissions));
  }, [data])

  const getSearchResults = (e) => {
    dispatch(setSearchText(e.target.value))
    debouncer({ variables: { searchText: e.target.value, limit: 50, offset: 0 } })
  }

  const materialCount = useSelector((state) => state.material.totalCount);
  const currentMaterialCount = useSelector((state) => state.material.currentCount);
  const processCount = useSelector((state) => state.process.totalCount);
  const currentProcessCount = useSelector((state) => state.process.currentCount);
  const totalCount = activeTab === 'material' ? materialCount : processCount;
  const currentCount = activeTab === 'material' ? currentMaterialCount : currentProcessCount;
  return (
    <div>
      <TableScrollerWrapper>
          <TableWrapper/>
          {useSelector((state) => state.showSearch.value) && <SearchBar getSearchResults={getSearchResults} loading={loading} totalCount={totalCount}/>}
      </TableScrollerWrapper>
      {totalCount > 50 && (<TableCountWrapper>
        <TableCountVal>Showing {currentCount} of {totalCount} results</TableCountVal>
        <LazyButton disabledButton={currentCount === totalCount}/>
      </TableCountWrapper>)}
    </div>
  );
}

export default SearchResults;
