import SearchIcon from '../components/SearchIcon';
import { useDispatch, useSelector } from 'react-redux';
import { setSearchVisibility } from '../app/universalSearchSlice';
import { setActiveTab } from '../app/tabSlice';
import { HeaderWrapper, HeaderLeftWrapper, HeaderTab, HeaderRightWrapper, ContextWrapper, ActiveContext, SearchTerm } from './styles';

function Header() {
  const dispatch = useDispatch();
  const activeTab = useSelector((state) => state.tab.value);
  const searchTerm = useSelector((state) => state.searchValue.value);

    return (
      <HeaderWrapper>
        <HeaderLeftWrapper activeLeft={activeTab === 'material'}>
          {/* <HeaderTab onClick={() => dispatch(setActiveTab('material'))} activeTab={activeTab === 'material'}>Material</HeaderTab>
          <HeaderTab onClick={() => dispatch(setActiveTab('process'))} activeTab={activeTab === 'process'}>Process</HeaderTab> */}
        </HeaderLeftWrapper>
        <HeaderRightWrapper onClick={() => dispatch(setSearchVisibility(true))}>
          <ContextWrapper>
            <ActiveContext>{activeTab} | </ActiveContext>
            <SearchTerm>{searchTerm}</SearchTerm>
          </ContextWrapper>
          <SearchIcon/>
        </HeaderRightWrapper>
      </HeaderWrapper>
    );
  }
  
  export default Header;
  