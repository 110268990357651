import React, { useEffect, useState } from 'react';
import { useTable } from 'react-table';
import { useSelector } from 'react-redux';
import { TableStyles } from './styles';

function Table({ columns, data }) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  });

  // Render the UI for your table
  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps()}>{column.render('Header')}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row)
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => {
                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

function TableWrapper() {
  const [formattedTableData, setFormattedTableData] = useState([]);
  const activeTab = useSelector((state) => state.tab.value);
  const materialData = useSelector((state) => state.material.value);
  const processData = useSelector((state) => state.process.value);
  const tableData = activeTab === 'material' ? materialData : processData;

  const columns = 
    // () => [
      [
      {
        Header: " ",
        columns: [
          {
            id: 'materialName',
            Header: activeTab === 'material' ? 'Material Name' : 'Process Name',
            accessor: 'materialName',
          },
          {
            id: 'carbonEmission',
            Header: 'Carbon Emission',
            accessor: 'carbonEmission',
          },
          {
            id: 'carbonEmissionUnit',
            Header: 'Unit',
            accessor: 'carbonEmissionUnit',
          },
          {
            id: 'location',
            Header: 'Location',
            accessor: 'location',
          },
          {
            id: 'dataSource',
            Header: 'Data Source',
            accessor: 'dataSource',
          },
        ],
      }
    ];
    // []
  // )

  // console.log("TableData", tableData);

  useEffect(() => {
    let formattedData = [];
    tableData !== 'undefined' && tableData.forEach(val => {
      let formattedObject = {};
      formattedObject.id = val.id;
      formattedObject.materialName = val.name;
      formattedObject.carbonEmission = val.emission ? val.emission.value : "NA";
      formattedObject.carbonEmissionUnit = val.emission ? val.emission.unit : "NA";
      formattedObject.location = val.location ? val.location.name : "NA";
      formattedObject.dataSource = val.dataset.name;
      formattedData.push(formattedObject);
    });
    setFormattedTableData(formattedData);
  }, [tableData]);

  return (
    <TableStyles>
      <Table columns={columns} data={formattedTableData}/>
    </TableStyles>
  )
}

export default TableWrapper
