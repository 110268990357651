import styled from 'styled-components';

export const SearchBackground = styled.div`
    position: absolute;
    background-color: transparent;
    left: 0;
    top: 40px;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const SearchWrapper = styled.div`
    background-color: #323336;
    padding-right: 15px;
    padding-left: 15px;
    border-bottom: 1px solid #4D4D50;
`;

export const SearchInputWrapper = styled.div`
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
`;

export const SearchResultCount = styled.span`
    color: rgba(255, 255, 255, 0.38);
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
`;

export const SearchInput = styled.input`
    border: none;
    background-color: transparent;
    height: 50px;
    width: 400px;
    padding-left: 10px;
    position: relative;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: rgba(255, 255, 255, 0.87);
    :focus {
        outline: none;
      }
`

export const SearchContextWrapper = styled.div`
    text-transform: capitalize;
    color: rgba(255, 255, 255, 0.87);
`;

export const SearchActiveContext = styled.div`
    padding: 8px 16px;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08)), #202124;
    cursor: pointer;
`;

export const SearchInactiveContext = styled.div`
    padding: 8px 16px;
    background-color: #3f4043;
    cursor: pointer;
    :hover{
        background-color: #202124;
    }
`;

export const LoadMore = styled.button`
    margin-top: 20px;
    cursor: pointer;
`;