import styled from 'styled-components';

export const HeaderWrapper = styled.div`
display: flex;
padding: 10px;
align-items: flex-end;
justify-content: space-between;
svg{
  cursor: pointer;
}
`;

export const HeaderLeftWrapper = styled.div`
  color: rgba(255, 255, 255, 0.6);
  display: flex;
  position: relative;
  ::after{
    position: absolute;
    bottom: -10px;
    content: "";
    left: ${props => props.activeLeft ? '0' : '50%'};
    width: 50%;
    background-color: #FFC80F;
    height: 3px;
    transition: all 0.3s ease-in-out;
  }
`;

export const HeaderTab = styled.div`
  padding: 0 10px;
  cursor: pointer;
  color: ${props => props.activeTab ? 'rgba(255,255,255,0.8)' : 'rgba(255,255,255,0.6)'}
`;

export const HeaderRightWrapper = styled.div`
  display: flex;
`;

export const ContextWrapper = styled.div`
  margin-right: 25px;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
`;

export const ActiveContext = styled.span`
  text-transform: capitalize;
`;

export const SearchTerm = styled.span``;

export const TableStyles = styled.div`

  table {
    border-spacing: 0;
    border: 1px solid black;
    width: 100%;
    color: rgba(255, 255, 255, 0.6);
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
      :nth-child(even) {
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08)), #202124;
      }
    }

    thead {
      tr {
        :first-child {
          display: none;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border: 1px solid #4D4D50;
      border-right: 1px solid #4D4D50;
      :nth-child(1){
        color: rgba(255, 255, 255, 0.87);
      }
      :last-child {
        border-right: 0;
      }
      :nth-child(2){
        text-align: right;
        border-right: 1px solid transparent;
      }
      :nth-child(3){
        border-left: 1px solid transparent;
      }
    }

    th {
        background: linear-gradient(0deg, rgba(255, 255, 255, 0.14), rgba(255, 255, 255, 0.14)), #202124;
        color: rgba(255, 255, 255, 0.6) !important;
        text-align: left;
    }
  }
`