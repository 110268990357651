import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLazyQuery } from '@apollo/client';
import { debounce } from 'lodash';
import { appendMaterialData } from '../app/materialSlice';
import { appendProcessData } from '../app/processSlice';
import { LIST_MATERIALS } from '../Queries';
import { LIST_PROCESS } from '../Queries';
import { LoadMore } from './styles';

function LazyButton(props) {
  const dispatch = useDispatch();
  const activeTab = useSelector((state) => state.tab.value);
  // const { loading, error, data } = useQuery(activeTab === 'material' ? LIST_MATERIALS : LIST_PROCESS);
  const [search, { loading, data }] = useLazyQuery(activeTab === 'material' ? LIST_MATERIALS : LIST_PROCESS);
  const debouncer = useCallback(debounce(search, 1000), []);
  // console.log("TableData", val);
  const currentMaterialCount = useSelector((state) => state.material.currentCount);
  const currentProcessCount = useSelector((state) => state.process.currentCount);
  const currentCount = activeTab === 'material' ? currentMaterialCount : currentProcessCount;
  const searchText = useSelector((state) => state.searchValue.value);

  useEffect(() => {
    data && activeTab === 'material' && dispatch(appendMaterialData(data.listMaterialsWithCarbonEmissions));
    data && activeTab === 'process' && dispatch(appendProcessData(data.listProcessWithCarbonEmissions));
  }, [data])

  const loadMore = () => {
    debouncer({ variables: { searchText: searchText, limit: 50, offset: currentCount } })
  }
  
    return (
      <LoadMore onClick={() => loadMore()} disabled={loading || props.disabledButton}>
        {loading ? 'Loading More' : 'Load More'}
      </LoadMore>
    );
  }
  
  export default LazyButton;
  