import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: [],
  totalCount: 0,
  currentCount: 0,
}

export const materialSlice = createSlice({
  name: 'materials',
  initialState,
  reducers: {
    addMaterialData: (state, action) => {
      state.value = action.payload.materials
      state.totalCount = action.payload.totalCount
      state.currentCount = 50
    },
    appendMaterialData: (state, action) => {
      state.value = state.value.concat(action.payload.materials)
      state.currentCount += action.payload.materials.length
    },
  },
})

// Action creators are generated for each case reducer function
export const { addMaterialData } = materialSlice.actions
export const { appendMaterialData } = materialSlice.actions

export default materialSlice.reducer