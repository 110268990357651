import { useEffect } from "react";
import { useDispatch } from 'react-redux';
import { setSearchVisibility } from './app/universalSearchSlice';
import SearchResults from './Pages/SearchResults';
import Header from './containers/Header';
import styled from 'styled-components';

// TODO : Implement theme functionality

const AppBackground = styled.div`
height: 100vh;
overflow-y: scroll;
background: linear-gradient(0deg, rgba(255, 255, 255, 0.11), rgba(255, 255, 255, 0.11)), #202124;
`;

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    window.addEventListener('keydown', function (e) {
      if (e.key === '/') {
        e.preventDefault();
        dispatch(setSearchVisibility(true));
      }
      if (e.key === 'Enter' || e.key === 'Escape'){
        dispatch(setSearchVisibility(false));
      }
    });
    return () => window.removeEventListener('keydown', function(e){
      console.log("Removing event listener");
    });
  });


  return (
    <AppBackground>
      <Header/>
      <SearchResults/>
    </AppBackground>
  );
}

export default App;
