
import { gql } from '@apollo/client';

// export const ListMaterialsWithCarbonEmissions = gql`
// query ListMaterials($searchText: String!) {
//   listMaterialsWithCarbonEmissions(searchText: $searchText) {
//     name,
//     type,
//     id,
//     location {
//       id,
//       code,
//       name,
//       type,
//     }
//     emission {
//       id,
//       value,
//       unit
//     }
//     dataset {
//       id,
//       name
//     }
//   }
// }
// `;

export const LIST_MATERIALS = gql`
query ListMaterials($searchText: String!, $limit: Int, $offset: Int) {
  listMaterialsWithCarbonEmissions(searchText: $searchText, limit: $limit, offset: $offset) {
    materials {
      id
      name
      emission {
        id
        unit
        value
      }
      location {
        id
        name
      }
      dataset {
        id
        name
      }
    }
    totalCount
  }
}
`;

export const LIST_PROCESS = gql`
query ListProcesses($searchText: String!, $limit: Int, $offset: Int) {
  listProcessWithCarbonEmissions(searchText: $searchText, limit: $limit, offset: $offset) {
    processess {
      id
      name
      emission {
        unit
        value
      }
      location {
        name
      }
      dataset {
        name
      }
    }
    totalCount
  }
}
`