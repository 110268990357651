import { configureStore } from '@reduxjs/toolkit';
import materialReducer from './materialSlice';
import processReducer from './processSlice';
import tabReducer from './tabSlice';
import universalSearchReducer from './universalSearchSlice';
import universalSearchValueReducer from './universalSearchValueSlice';

export const store = configureStore({
  reducer: {
    material: materialReducer,
    process: processReducer,
    tab: tabReducer,
    showSearch: universalSearchReducer,
    searchValue: universalSearchValueReducer
  },
})