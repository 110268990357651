import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSearchVisibility } from '../app/universalSearchSlice';
import { setActiveTab } from '../app/tabSlice';
import SearchIcon from './SearchIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SearchBackground, SearchInputWrapper, SearchWrapper, SearchInput, SearchResultCount, SearchContextWrapper, SearchActiveContext, SearchInactiveContext } from './styles';

function SearchBar(props) {
    const [showOptions, setShowOptions] = useState(false);
    const dispatch = useDispatch();
    const activeTab = useSelector((state) => state.tab.value);
    
    const hideSearchBar = (e) => {
        if(e.target !== e.currentTarget) return;
        dispatch(setSearchVisibility(false))
    }

    const changeActiveTab = () => {
        dispatch(setActiveTab(activeTab === 'material' ? 'process' : 'material'));
        setShowOptions(false);
    }

    return (
        <SearchBackground onClick={(e) => hideSearchBar(e)}>
            <SearchInputWrapper>
            <SearchWrapper>
                <SearchIcon/>
                <SearchInput id="search" type="text" placeholder="Search" onChange={e => props.getSearchResults(e)} autoFocus defaultValue={useSelector((state) => state.searchValue.value)}/>
                <SearchResultCount>{props.loading ? 'loading' : (props.totalCount ? props.totalCount : 0)} results</SearchResultCount>
            </SearchWrapper>
            <SearchContextWrapper>
                <SearchActiveContext onClick={() => setShowOptions(!showOptions)}>{activeTab} <FontAwesomeIcon icon="chevron-down" color="rgba(255,255,255,0.6)"/></SearchActiveContext>
                {showOptions && <SearchInactiveContext onClick={() => changeActiveTab()}>{activeTab === 'material' ? 'process' : 'material'}</SearchInactiveContext>}
            </SearchContextWrapper>
            </SearchInputWrapper>
        </SearchBackground>
    );
  }
  
  export default SearchBar;
  