import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: '',
}

export const universalSearchValueSlice = createSlice({
  name: 'searchValue',
  initialState,
  reducers: {
    setSearchText: (state, action) => {
      state.value = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setSearchText } = universalSearchValueSlice.actions

export default universalSearchValueSlice.reducer