import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: true,
}

export const universalSearchSlice = createSlice({
  name: 'showSearch',
  initialState,
  reducers: {
    setSearchVisibility: (state, action) => {
      state.value = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setSearchVisibility } = universalSearchSlice.actions

export default universalSearchSlice.reducer