import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: 'material',
}

export const tabSlice = createSlice({
  name: 'tabs',
  initialState,
  reducers: {
    setActiveTab: (state, action) => {
      state.value = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setActiveTab } = tabSlice.actions

export default tabSlice.reducer